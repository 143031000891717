import React from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from "react-image-gallery";
import {album} from "../album";
import {useEffect, useState} from "react";

function Überuns() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Über uns"
    return (
        <div>
            {isMobile ? (
                <div>
                    <div style={{position: "relative"}}>
                        <div className={'übertitelmobile'}><h3>Über uns</h3></div>
                        <div className={"uebergridmobile"}>
                            <div className={"Porträtmobile"}><h2><b>Unser Team</b></h2>
                                <img className={"teambildmobile"} src={require('../images/Team3 bearbeitet.jpg')}
                                     width="60%"/>
                                <p>Unser Team setzt sich sowohl aus Therapeuten, die Jahrzehnte an Erfahrung haben, als
                                    auch aus jungen qualifizierten Kolleginnen zusammen. Wir haben zwei Vollzeit- und
                                    vier Teilzeitkräfte. Eine Kollegin ist in Elternzeit, aber unterstützt uns
                                    beratend.</p>
                                <p>Neben dem Therapieerfolg ist es ein großes Anliegen von uns, dass die Patienten sich
                                    wohl bei uns fühlen, denn nur so kann die Therapie gelingen. Außerdem erachten wir
                                    eine ganzheitliche Arbeit mit Einbeziehung und Zusammenarbeit(wenn nötig und
                                    erwünscht) von anderen Berufsgruppen für hilfreich und wichtig.</p>
                            </div>
                            <div className={"Porträtmobile"}><h2><b>Sandra Duffy-Heusel</b></h2>
                                <img className={"teambildmobile"} src={require('../images/SandraDH2.jpg')} width="40%"/>
                                <p>Als gebürtige Irin absolvierte ich nach meiner Schulzeit ein Studium als Speech and
                                    Language Pathologist mit dem Abschluss B.A. Remedial Linguistics am Trinity College
                                    Dublin. Nach Anerkennung meines Studiums in Deutschland arbeitete ich ab 1984 an
                                    einer Schule für Körperbehinderte in Aschaffenburg. Seit Mai 1988 bin ich als
                                    selbstständige Logopädin und Leiterin dieser Praxis in Breuberg-Neustadt tätig.</p>

                                <p>Ich habe im Laufe der Jahre viel Erfahrung im Bereich der Frühkindlichen
                                    Entwicklungsstörungen sammeln können und arbeite aktuell unter anderem mit vielen
                                    jungen kaum sprechenden Kindern und biete auch Unterstützte Kommunikation an.</p>

                                <p>Ich bin verheiratet und habe drei erwachsene Kinder</p>
                            </div>
                            <div className={"Porträtmobile"}><h2><b>Jennifer Weyrauch</b></h2>
                                <img className={"teambildmobile"} src={require('../images/JenniW2.jpg')} width="40%"/>
                                <p>Nach meinem Abitur war ich fast ein Jahr als Praktikantin in einem Städtischen
                                    Kindergarten der Stadt Michelstadt tätig. Von 2000 bis 2003 absolvierte ich meine
                                    Ausbildung zur Logopädin an der Lehranstalt für Logopädie der
                                    Johannes-Gutenberg-Universität in Mainz. Seit meinem Abschluss 2003 arbeite ich als
                                    staatlich anerkannte Logopädin in dieser Praxis.</p>

                                <p>Ich bin verheiratet und habe drei Kinder. Seit der Geburt meines ersten Kindes (2012)
                                    arbeite ich in Teilzeit.</p>

                                <p>Ich behandle alle Störungsbilder und habe mich insbesondere im Bereich der
                                    frühkindlichen Schluckstörungen bzw. Fütter- und Essstörungen sowie Dysphagien
                                    allgemein (Schluckstörungen) spezialisiert. Hierbei therapiere ich unter anderem
                                    nach Castillo Morales. Ein weiterer Schwerpunkt meiner Therapie ist die Unterstützte
                                    Kommunikation. In diesem Zusammenhang arbeite ich auch als Logopädin in
                                    Einrichtungen.</p>
                            </div>
                            <div className={"Porträtmobile"}><h2><b>Judit Schneider</b></h2>
                                <img className={"teambildmobile"} src={require('../images/JuditS2.jpg')} width="40%"/>
                                <p>Meine Ausbildung zur staatlich anerkannten Logopädin absolvierte ich auf der
                                    Fachhochschule Fresenius Lehranstalt für Logopädie in Darmstadt im Jahr 2007 mit dem
                                    Staatsexamen u.a. in neurologisch bedingten Sprachstörungen. Es gab mir eine sehr
                                    gute Basis und große Neugier für meine Arbeit auch mit Patienten, die durch
                                    neurologische Erkrankungen unter Sprach- oder Sprechstörungen leiden.</p>

                                <p>Seit 2009 arbeite ich in der Logopädischen Praxis Sandra Duffy-Heusel, aktuell in
                                    einer 90% Stelle. Die Sprech- und Sprachtherapie bei Kindern gehört zum
                                    Grundwerkzeug einer Logopädin, welche von meiner Arbeit nicht wegzudenken und eine
                                    Herzensangelegenheit ist.</p>

                                <p>Durch das berufsbegleitende Studium auf dem Konservatorium in Bratislava in Slowakei
                                    mit Schwerpunkt Gesang, Instrument Klavier gilt meine besondere Liebe der Arbeit mit
                                    Stimmpatienten. Das erworbene Wissen in der Ausbildung auf der Medizinischen
                                    Fachschule im Fachgebiet Rehabilitation in Banska Bystrica in Slowakei rundet meine
                                    Arbeit hier gut ab.</p>

                                <p>Aufgrund meiner tiefen Verbundenheit zur Natur, die ich mit meinem Ehemann seit
                                    vielen Jahren teile, ist mir ein ganzheitlicher Umgang mit Menschen besonders
                                    wichtig.</p>
                            </div>
                            <div className={"Porträtmobile"}><h2><b>Michaela Neff</b></h2>
                                <img className={"teambildmobile"} src={require('../images/MichaelaN2.jpg')}
                                     width="40%"/>
                                <p>Nach meinem Abitur im Jahr 1993 studierte ich zunächst Slawistik und
                                    Politikwissenschaften in Heidelberg. Im Anschluss folgte ein Freiwilliges Soziales
                                    Jahr bei einer Einrichtung, die behinderte Kinder und Jugendliche betreut. In dieser
                                    Zeit reifte der Gedanke, dass ich lieber einen sozial-therapeutischen Beruf
                                    ergreifen wollte.</p>

                                <p>Ich absolvierte dann von 1997 bis 2000 meine Ausbildung zur staatlich geprüften
                                    Logopädin an der Fresenius Fachhochschule für Logopädie in Darmstadt/Eberstadt. Bis
                                    zur Geburt meines ersten Kindes arbeitete ich Vollzeit in logopädischen Praxen in
                                    Würzburg und Michelstadt. Nach der Geburt meines zweiten Kindes begann ich als
                                    Teilzeitkraft in der Logopädischen Praxis Duffy-Heusel, in der ich jetzt seit über
                                    10 Jahren tätig bin.</p>

                                <p>Ich unterstütze das Team schwerpunktmäßig in den Bereichen Kindersprache und
                                    Stimmtherapie. Hierzu besuche ich regelmäßig Fortbildungen und arbeite in der
                                    Stimmtherapie in Anlehnung an den Funktionalen Ansatz des Lichtenberger
                                    Instituts.</p>
                            </div>
                            <div className={"Porträtmobile"}><h2><b>Christiane Eidenmüller</b></h2>
                                <img className={"teambildmobile"} src={require('../images/ChristianeE (2).jpg')}
                                     width="40%"/>
                                <p>Ich absolvierte nach meinem Abitur die Ausbildung zur Logopädin in Mainz, sowie den
                                    dualen Bachelorstudiengang Gesundheit und Pflege an der Katholischen Hochschule
                                    Mainz. Nach abgeschlossener Ausbildung 2012 während der zweiten Studienphase
                                    arbeitete ich stundenweise in logopädischen Praxen in Mainz und Wiesbaden.</p>

                                <p>Seit 2014 gehöre ich zum Team der logopädischen Praxis Duffy-Heusel.</p>

                                <p>Im Bereich neurologischer Sprach-, Sprech- und Schluckstörungen konnte ich seither
                                    schon zahlreiche Erfahrungen sammeln. Die Begleitung dieser Patienten liegt mir sehr
                                    am Herzen.</p>

                                <p>Neben meiner therapeutischen Arbeit gab ich auch Fortbildungen für Lehrkräfte mit
                                    Tipps zum gesunden Umgang mit der Stimme.</p>

                                <p>Seit der Geburt meiner Tochter 2019 arbeite ich in Teilzeit.</p>
                            </div>
                            <div className={"Porträtmobile"}><h2><b>Fabienne Pietrusky</b></h2>
                                <img className={"teambildmobile"} src={require('../images/FabienneP (2).jpg')}
                                     width="40%"/>
                                <p>Ich startete nach meinem Abitur 2008 die Ausbildung zur Logopädin in Saarbrücken.
                                    Schon während der Ausbildung entdeckte ich durch Praktika meine Leidenschaft für den
                                    Fachbereich Neurologie.</p>

                                <p>Ab 2012 arbeitete ich sowohl in verschiedenen Praxen und als auch stationär mit dem
                                    Schwerpunkt Schluckstörungen. Berufsbegleitend schloss ich an der Hochschule
                                    Fresenius den Bachelor ab und vertiefte meine Kenntnisse im Fachbereich Neurologie
                                    durch Fortbildungen.</p>

                                <p>Ich arbeite als Teilzeitkraft in der Praxis.</p>
                            </div>
                            <div style={{paddingBottom: "0px"}} className={"Porträtmobile"}><h2><b>Nadine Stützer</b>
                            </h2>
                                <img className={"teambildmobile"} src={require('../images/NadineS2.jpg')} width="40%"/>
                                <p>Ich habe 2009 meine Ausbildung zur Medizinischen Fachangestellten abgeschlossen und
                                    arbeitete bis 2017 in diesem Beruf. Durch eine berufsbedingte Erkrankung wurde eine
                                    Umschulung empfohlen, was mich zur Logopädie brachte.</p>

                                <p>Im Juli 2020 absolvierte ich das Examen zur Logopädin an der IB Medizinischen
                                    Akademie in Aschaffenburg.</p>

                                <p>Das erste Praktikum wurde in dieser Praxis gemacht und schon innerhalb dieser Zeit
                                    war beidseits klar, dass ich im Anschluss hier angestellt werden könnte.</p>

                                <p>Seit Juni 2022 befinde ich mich in Elternzeit.</p>
                            </div>
                        </div>
                    </div>
                    <div className={"albummobile"}>
                        <p style={{padding: "2%", paddingBottom: "0px", fontSize: "small"}}><b>Unsere Praxisräume</b>
                        </p>
                        <ImageGallery
                            items={album}
                            useBrowserFullscreen={false}/>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{position: "relative"}}>
                        <div className={'übertitel'}><h3>Über uns</h3></div>
                        <div className={"uebergrid"}>
                            <div className={"Porträt"}><h4><b>Unser Team</b></h4>
                                <img className={"teambild"} src={require('../images/Team3 bearbeitet.jpg')}
                                     width="340"/>
                                <p>Unser Team setzt sich sowohl aus Therapeuten, die Jahrzehnte an Erfahrung haben, als
                                    auch aus jungen qualifizierten Kolleginnen zusammen. Wir haben zwei Vollzeit- und
                                    vier Teilzeitkräfte. Eine Kollegin ist in Elternzeit, aber unterstützt uns
                                    beratend.</p>
                                <p>Neben dem Therapieerfolg ist es ein großes Anliegen von uns, dass die Patienten sich
                                    wohl bei uns fühlen, denn nur so kann die Therapie gelingen. Außerdem erachten wir
                                    eine ganzheitliche Arbeit mit Einbeziehung und Zusammenarbeit(wenn nötig und
                                    erwünscht) von anderen Berufsgruppen für hilfreich und wichtig.</p>
                            </div>
                            <div className={"Porträt"}><h4><b>Sandra Duffy-Heusel</b></h4>
                                <img className={"teambild"} src={require('../images/SandraDH2.jpg')} width="200"/>
                                <p>Als gebürtige Irin absolvierte ich nach meiner Schulzeit ein Studium als Speech and
                                    Language Pathologist mit dem Abschluss B.A. Remedial Linguistics am Trinity College
                                    Dublin. Nach Anerkennung meines Studiums in Deutschland arbeitete ich ab 1984 an
                                    einer Schule für Körperbehinderte in Aschaffenburg. Seit Mai 1988 bin ich als
                                    selbstständige Logopädin und Leiterin dieser Praxis in Breuberg-Neustadt tätig.</p>

                                <p>Ich habe im Laufe der Jahre viel Erfahrung im Bereich der Frühkindlichen
                                    Entwicklungsstörungen sammeln können und arbeite aktuell unter anderem mit vielen
                                    jungen kaum sprechenden Kindern und biete auch Unterstützte Kommunikation an.</p>

                                <p>Ich bin verheiratet und habe drei erwachsene Kinder</p>
                            </div>
                            <div className={"Porträt"}><h4><b>Jennifer Weyrauch</b></h4>
                                <img className={"teambild"} src={require('../images/JenniW2.jpg')} width="200"/>
                                <p>Nach meinem Abitur war ich fast ein Jahr als Praktikantin in einem Städtischen
                                    Kindergarten der Stadt Michelstadt tätig. Von 2000 bis 2003 absolvierte ich meine
                                    Ausbildung zur Logopädin an der Lehranstalt für Logopädie der
                                    Johannes-Gutenberg-Universität in Mainz. Seit meinem Abschluss 2003 arbeite ich als
                                    staatlich anerkannte Logopädin in dieser Praxis.</p>

                                <p>Ich bin verheiratet und habe drei Kinder. Seit der Geburt meines ersten Kindes (2012)
                                    arbeite ich in Teilzeit.</p>

                                <p>Ich behandle alle Störungsbilder und habe mich insbesondere im Bereich der
                                    frühkindlichen Schluckstörungen bzw. Fütter- und Essstörungen sowie Dysphagien
                                    allgemein (Schluckstörungen) spezialisiert. Hierbei therapiere ich unter anderem
                                    nach Castillo Morales. Ein weiterer Schwerpunkt meiner Therapie ist die Unterstützte
                                    Kommunikation. In diesem Zusammenhang arbeite ich auch als Logopädin in
                                    Einrichtungen.</p>
                            </div>
                            <div className={"Porträt"}><h4><b>Judit Schneider</b></h4>
                                <img className={"teambild"} src={require('../images/JuditS2.jpg')} width="200"/>
                                <p>Meine Ausbildung zur staatlich anerkannten Logopädin absolvierte ich auf der
                                    Fachhochschule Fresenius Lehranstalt für Logopädie in Darmstadt im Jahr 2007 mit dem
                                    Staatsexamen u.a. in neurologisch bedingten Sprachstörungen. Es gab mir eine sehr
                                    gute Basis und große Neugier für meine Arbeit auch mit Patienten, die durch
                                    neurologische Erkrankungen unter Sprach- oder Sprechstörungen leiden.</p>

                                <p>Seit 2009 arbeite ich in der Logopädischen Praxis Sandra Duffy-Heusel, aktuell in
                                    einer 90% Stelle. Die Sprech- und Sprachtherapie bei Kindern gehört zum
                                    Grundwerkzeug einer Logopädin, welche von meiner Arbeit nicht wegzudenken und eine
                                    Herzensangelegenheit ist.</p>

                                <p>Durch das berufsbegleitende Studium auf dem Konservatorium in Bratislava in Slowakei
                                    mit Schwerpunkt Gesang, Instrument Klavier gilt meine besondere Liebe der Arbeit mit
                                    Stimmpatienten. Das erworbene Wissen in der Ausbildung auf der Medizinischen
                                    Fachschule im Fachgebiet Rehabilitation in Banska Bystrica in Slowakei rundet meine
                                    Arbeit hier gut ab.</p>

                                <p>Aufgrund meiner tiefen Verbundenheit zur Natur, die ich mit meinem Ehemann seit
                                    vielen Jahren teile, ist mir ein ganzheitlicher Umgang mit Menschen besonders
                                    wichtig.</p>
                            </div>
                            <div className={"Porträt"}><h4><b>Michaela Neff</b></h4>
                                <img className={"teambild"} src={require('../images/MichaelaN2.jpg')} width="200"/>
                                <p>Nach meinem Abitur im Jahr 1993 studierte ich zunächst Slawistik und
                                    Politikwissenschaften in Heidelberg. Im Anschluss folgte ein Freiwilliges Soziales
                                    Jahr bei einer Einrichtung, die behinderte Kinder und Jugendliche betreut. In dieser
                                    Zeit reifte der Gedanke, dass ich lieber einen sozial-therapeutischen Beruf
                                    ergreifen wollte.</p>

                                <p>Ich absolvierte dann von 1997 bis 2000 meine Ausbildung zur staatlich geprüften
                                    Logopädin an der Fresenius Fachhochschule für Logopädie in Darmstadt/Eberstadt. Bis
                                    zur Geburt meines ersten Kindes arbeitete ich Vollzeit in logopädischen Praxen in
                                    Würzburg und Michelstadt. Nach der Geburt meines zweiten Kindes begann ich als
                                    Teilzeitkraft in der Logopädischen Praxis Duffy-Heusel, in der ich jetzt seit über
                                    10 Jahren tätig bin.</p>

                                <p>Ich unterstütze das Team schwerpunktmäßig in den Bereichen Kindersprache und
                                    Stimmtherapie. Hierzu besuche ich regelmäßig Fortbildungen und arbeite in der
                                    Stimmtherapie in Anlehnung an den Funktionalen Ansatz des Lichtenberger
                                    Instituts.</p>
                            </div>
                            <div className={"Porträt"}><h4><b>Christiane Eidenmüller</b></h4>
                                <img className={"teambild"} src={require('../images/ChristianeE (2).jpg')} width="200"/>
                                <p>Ich absolvierte nach meinem Abitur die Ausbildung zur Logopädin in Mainz, sowie den
                                    dualen Bachelorstudiengang Gesundheit und Pflege an der Katholischen Hochschule
                                    Mainz. Nach abgeschlossener Ausbildung 2012 während der zweiten Studienphase
                                    arbeitete ich stundenweise in logopädischen Praxen in Mainz und Wiesbaden.</p>

                                <p>Seit 2014 gehöre ich zum Team der logopädischen Praxis Duffy-Heusel.</p>

                                <p>Im Bereich neurologischer Sprach-, Sprech- und Schluckstörungen konnte ich seither
                                    schon zahlreiche Erfahrungen sammeln. Die Begleitung dieser Patienten liegt mir sehr
                                    am Herzen.</p>

                                <p>Neben meiner therapeutischen Arbeit gab ich auch Fortbildungen für Lehrkräfte mit
                                    Tipps zum gesunden Umgang mit der Stimme.</p>

                                <p>Seit der Geburt meiner Tochter 2019 arbeite ich in Teilzeit.</p>
                            </div>
                            <div className={"Porträt"}><h4><b>Fabienne Pietrusky</b></h4>
                                <img className={"teambild"} src={require('../images/FabienneP (2).jpg')} width="200"/>
                                <p>Ich startete nach meinem Abitur 2008 die Ausbildung zur Logopädin in Saarbrücken.
                                    Schon während der Ausbildung entdeckte ich durch Praktika meine Leidenschaft für den
                                    Fachbereich Neurologie.</p>

                                <p>Ab 2012 arbeitete ich sowohl in verschiedenen Praxen und als auch stationär mit dem
                                    Schwerpunkt Schluckstörungen. Berufsbegleitend schloss ich an der Hochschule
                                    Fresenius den Bachelor ab und vertiefte meine Kenntnisse im Fachbereich Neurologie
                                    durch Fortbildungen.</p>

                                <p>Ich arbeite als Teilzeitkraft in der Praxis.</p>
                            </div>
                            <div style={{paddingBottom: "120%"}} className={"Porträt"}><h4><b>Nadine Stützer</b></h4>
                                <img className={"teambild"} src={require('../images/NadineS2.jpg')} width="200"/>
                                <p>Ich habe 2009 meine Ausbildung zur Medizinischen Fachangestellten abgeschlossen und
                                    arbeitete bis 2017 in diesem Beruf. Durch eine berufsbedingte Erkrankung wurde eine
                                    Umschulung empfohlen, was mich zur Logopädie brachte.</p>

                                <p>Im Juli 2020 absolvierte ich das Examen zur Logopädin an der IB Medizinischen
                                    Akademie in Aschaffenburg.</p>

                                <p>Das erste Praktikum wurde in dieser Praxis gemacht und schon innerhalb dieser Zeit
                                    war beidseits klar, dass ich im Anschluss hier angestellt werden könnte.</p>

                                <p>Seit Juni 2022 befinde ich mich in Elternzeit.</p>
                            </div>
                        </div>
                    </div>
                    <div className={"album"}>
                        <h3 style={{paddingLeft: "80px", paddingBottom: "10px"}}><b>Unsere Praxisräume</b></h3>
                        <ImageGallery
                            items={album}
                            useBrowserFullscreen={false}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Überuns;

/*<div style={{paddingBottom: "750px"}} className={"Porträt"}><h4><b>Sophie Bischof</b></h4>
    <img className={"teambild"} src={require('../images/Sophie.jpeg')} width="200"/>
    <p>Nach meinem Abitur absolvierte ich ein Freiwilliges Soziales Jahr in Frankfurt, an einer Schule mit dem
        Förderschwerpunkt Hören. Währenddessen machte ich mich mit verschiedenen Therapieberufen vertraut und lernte die
        Logopädie kennen und lieben. </p>

    <p>2020 startete ich meine Ausbildung zur staatlich anerkannten Logopädin an der IB Medizinischen Akademie in
        Aschaffenburg und durchlief im Frühjahr 2022 ein Praktikum in dieser Praxis. Schon damals konnten wir uns
        vorstellen, dass ich nach meinem Abschluss hier arbeiten könnte.</p>

    <p>Ich beendete im Sommer 2023 erfolgreich meine Ausbildung und arbeite seit Oktober 2023 Vollzeit in der
        Praxis.</p>
</div>*/

